<template>
	<b-form>
		<div class="a-exam row">
			<b-container class="mt-5">
				<div class="test">
					<div class="d-test-header">
						<b-row>
							<b-col>
								<h2 class="w-color-primary mb-5">Názov testu</h2>
							</b-col>
						</b-row>
						<b-row class="align-items-end">
							<b-col>
								<b-form-input v-model="exam.name" v-validate="{ required: true }" name="nameExam" placeholder="Názov testu" />
							</b-col>
							<b-col class="d-flex">
								<b-form-group id="anwsers-settings" class="d-flex" label="Nastavenie odpovedí">
									<b-form-checkbox
										v-model="exam.is_shuffling_questions"
										id="checkbox-1" class="mr-1" name="checkbox-1"
										v-b-tooltip.hover title="Zamieša poradie otázok v teste"
									>
										Zamiešať otázky
									</b-form-checkbox>
									<b-form-checkbox
										v-model="exam.is_shuffling_question_choices"
										id="checkbox-2" class="mx-1" name="checkbox-2"
										v-b-tooltip.hover title="Zamieša odpovede v otázke"
									>
										Zamiešať odpovede
									</b-form-checkbox>
									<b-form-checkbox
										v-model="exam.is_scored"
										id="checkbox-3" class="mx-1" name="checkbox-3"
										v-b-tooltip.hover title="Za správnu odpoveď priradí 1 bod, za nesprávnu 0 bodov"
									>
										Bodovanie odpovedí
									</b-form-checkbox>
									<b-form-checkbox 
										v-model="exam.is_history_allowed"
										id="checkbox-4" class="mx-1" name="checkbox-4"
										v-b-tooltip.hover title="Študenti si vedia v histórii pozrieť konkrétne otázky testu a správne odpovede"
									>
										Povoliť históriu
									</b-form-checkbox>
								</b-form-group>
							</b-col>
						</b-row>
					</div>

					<div class="test-content mt-3">
						<div role="tablist">
							<div>
								<a-questions ref="questions" :questions="exam.questions" />
							</div>
						</div>
					</div>
				</div>
			</b-container>

			<a-exam-share v-if="exam.id" modalId="ShareModal" :exam="exam" />
			<a-exam-duplicate v-if="exam.id" modalId="DuplicateModal" :exam="exam" @createdExamtemplate="showCreatedToast()" />

			<footer class="z-footer d-flex align-items-center w-100 py-3">
				<b-container>
					<b-row>
						<b-col class="d-flex align-items-center">
							<b-link v-if="exam.id" class="mr-4" href="#" @click="duplicateExam()">
								<img src="@/plugins/app/_theme/icon/copy.svg" class="a-icon --large" />
							</b-link>
							<b-link v-if="exam.id" class="mr-4" href="#" @click="shareExam()">
								<img src="@/plugins/app/_theme/icon/share.svg" class="a-icon --large" />
							</b-link>
							<b-link v-if="exam.id" class="mr-4" href="#" @click="deleteExam()">
								<img src="@/plugins/app/_theme/icon/delete.svg" class="a-icon --large" />
							</b-link>
						</b-col>
						<b-col class="d-flex align-items-center justify-content-end">
							<b-button href="#" class="mr-3 button --outline --no-border" @click.prevent="$router.push({ name: 'Tests' })">
								Zrušiť
							</b-button>
							<b-button class="button" :disabled="disabledButton" @click.prevent="saveExam">
								Uložiť
							</b-button>
						</b-col>
					</b-row>
				</b-container>
			</footer>

			<z-loader v-if="loading"></z-loader>
		</div>
	</b-form>
</template>

<script>
import wAxios from '@/plugins/w/axios'

const api = {
	examtemplate: 			(examId) 				=> wAxios.get_auth(`/v1/teacher/examtemplates/${examId}`),
	updateExamtemplate: (examId, data) 	=> wAxios.put_auth(`/v1/teacher/examtemplates/${examId}`, data),
	createExamtemplate: (data) 					=> wAxios.post_auth(`/v1/teacher/examtemplates`, data),
	deleteExamtemplate: (examId) 				=> wAxios.delete_auth(`/v1/teacher/examtemplates/${examId}`)
}

export default {

	components: { 
		'a-exam-share': 		() => import('@/plugins/app@exams/_components/exam-share-modal/a-exam-share'),
		'a-exam-duplicate': () => import('@/plugins/app@exams/_components/exam-duplicate-modal/a-exam-duplicate'),
		'z-loader': 				() => import('@/plugins/app@components/loader/z-loader'),
		'a-questions': 			() => import('./a-questions'),
	},

	data() {
		return {
			exam: { 
				id: this.$route.params.id,
				name: '',
				is_shuffling_questions: false,
				is_shuffling_question_choices: false,
				is_scored: false,
				is_history_allowed: false,
				questions: [
					{
						name: '',
						type: 'choices',
						visible: false,
						studyArea: '',
						choices: [
							{ name: '', is_correct: false },
							{ name: '', is_correct: false }
						],
					},
				],
			},
			selected: 'choices',
			validationText: '',
			answerText: '',
			beforeEditingCache: null,
			disabledButton: false,
			loading: false
		}
	},

	async mounted() {
		if (!this.exam.id) {
			this.exam.questions[0].visible = true
			return
		}

		this.loading = true
		try {
			({data: { data: this.exam }} = await api.examtemplate(this.exam.id))
		} catch(err) {
			this.$wToast.error(err)
		} finally {
			this.loading = false
		}
	},

	methods: {
		saveExam() {
			let isExamValid = true
			
			// WARNING: asi to je hack, nepaci sa mi to, ale inak som to nevedel spravit
			this.$refs.questions.$refs.questionBody.forEach((questionBody, i) => {
				const isQuestionValid = questionBody.saveQuestion(this.exam.questions[i])
				if (!isQuestionValid) {
					isExamValid = false
					this.exam.questions[i].visible = true
				}
			})

			if (!isExamValid) {
				return
			}

			this.$validator.validateAll().then((result) => {
				if (!result) {
					return
				}

				this.disabledButton = true
				if (this.exam.id) {
					api.updateExamtemplate(this.exam.id, this.exam)
						.then(() => {
							this.$root.$bvToast.toast(`${this.exam.name}`, {
								title: 'Uložené',
								autoHideDelay: 5000,
								variant: 'a-success',
								toaster: 'b-toaster-top-center',
								solid: true
							})
						})
						.then(() => this.$router.push({name: 'Tests'}))
						.catch(err => console.error(err))
						.finally(() => this.disabledButton = false)
				} else {
					api.createExamtemplate(this.exam)
						.then(() => {
							this.$bvToast.toast(`s názvom: ${this.name}`, {
								title: 'Test bol úspešne uložený',
								autoHideDelay: 5000,
								variant: 'a-success',
								toaster: 'b-toaster-top-center',
								solid: true
							})
						})
						.then(() => this.$router.push({name: 'Tests'}))
						.catch(err => console.error(err))
						.finally(() => this.disabledButton = false)
				}

			})
		},

		async duplicateExam() {
			this.$bvModal.show('DuplicateModal')
		},

		async shareExam() {
			this.$bvModal.show('ShareModal')
		},

		async deleteExam() {
			try {
				const confirm = await this.$bvModal.msgBoxConfirm('Naozaj chcete vymazať tento test?', {
					title: 'Vymazať test?',
					okTitle: 'Vymazať',
					okVariant: 'a-primary',
					cancelTitle: 'Zrušiť',
					cancelVariant: 'a-primary--outline',
					buttonSize: 'sm',
					hideHeaderClose: false,
				})

				if (confirm) {
					await api.deleteExamtemplate(this.exam.id)
					this.$router.push({ name: 'Tests' })
				}
			} catch(err) {
				this.$wToast.error(err)
			}
		},

		showCreatedToast() {
			this.$bvToast.toast(
				`Test "${this.exam.name}" bol zduplikovaný!`,
				{
					title: 'Test úspešne zduplikovaný',
					autoHideDelay: 5000,
					variant: 'a-success',
					toaster: 'b-toaster-top-center',
					solid: true
				}
			)
		}
	},
}
</script>

<style lang='sass' scoped>
.a-exam
	margin-bottom: 12rem

.d-test-header
	padding: 10px

input[name='nameExam']
	font-size: 2.5rem
	font-weight: bold
	padding-left: 0
	color: var(--a-color-primary)

input
	border: none
	border-radius: 0
	border-bottom: 1px solid#D1D1E0

</style>